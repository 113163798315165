// Remix-i18n configuration file https://github.com/sergiodxa/remix-i18next

import SupportedLanguages from './constants/languages';

export default {
  supportedLngs: SupportedLanguages,
  fallbackLng: 'en',
  defaultNS: 'main',
  react: { useSuspense: false },
};
