import { RemixBrowser } from '@remix-run/react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { startTransition, StrictMode } from 'react';
import hydrator from 'react-dom';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { getInitialNamespaces } from 'remix-i18next';

import i18n from './i18n';

// invalidates the cache when a release occurs
const getVersionedLocalizationPath = () => {
  // @ts-ignore
  const i18nCacheVersion: string = window.i18n_cacheVersion;
  const isLocalhost = window.location.hostname.includes('localhost');

  const localesLoadPath = isLocalhost
    ? '/locales/{{lng}}/{{ns}}.json'
    : `/locales/{{lng}}/{{ns}}.json?version=${i18nCacheVersion}`;

  return localesLoadPath;
};

async function hydrate() {
  await i18next
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(LanguageDetector) // Setup a client-side language detector
    .use(Backend) // Setup your backend
    .init({
      ...i18n, // spread the configuration
      // This function detects the namespaces your routes rendered while SSR use
      ns: getInitialNamespaces(),
      backend: { loadPath: getVersionedLocalizationPath() },
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ['htmlTag'],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: [],
      },
      interpolation: {
        escapeValue: false, // react already safes from xss
        // Intl NumberFormat expects a Unicode BCP 47 locale identifier which differs from what we currently have
        format: (value, format, language) => {
          if (format === 'number') {
            try {
              return new Intl.NumberFormat(language).format(value);
            } catch {
              console.warn('Intl.NumberFormat is not supported, falling back to basic formatting');
              return value;
            }
          }
          return value;
        },
      },
    });

  startTransition(() => {
    hydrator.hydrate(
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </I18nextProvider>,
      document,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
